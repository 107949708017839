<template>
    <div id="belepes" class="login block--big position-relative bg-yellow">
        <div class="container-xl login__content">
            <h2 class="font-header text-secondary text-center pb-2 text-uppercase">Belépés</h2>                           
                <div class="row mt-5">                    
                           <!-- ITT KEZDŐDIK A FORM --> 
                            <ValidationObserver 
                                class="col-12 col-xl-8 offset-xl-2"
                                ref="observerLogin" 
                                v-slot="{ handleSubmit }"
                                tag="div"                                
                                data-formname="loginForm"
                            >
                                <div class="">
                                    <form 
                                        class="row "
                                        @submit.prevent="handleSubmit(onSubmit)"
                                    >
                                        <div class="col-12 col-md-6">
                                            <CustomInput
                                                label="E-mail cím"
                                                name="email"
                                                placeholder="pl.: molnarkata@gmail.com"
                                                tooltip="A játékos e-mail címe amit regisztrációnál használt."
                                                v-model="form.email"
                                                rules="required|email"
                                            />
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <CustomInput
                                                type="password"
                                                label="Jelszó"
                                                name="password"
                                                placeholder="Minimum 8 karakter"
                                                tooltip="Regisztráció során megadott jelszó: Legalább 8 karakternek kell lennie és tartalmaznia kell legalább 1 speciális karaktert 1 nagy és 1 kisbetűt."
                                                v-model="form.password"
                                                rules="required|min:8|customPsw"
                                            />

                                        </div>
                                        <div class="col-12 col-md-6 mx-auto" v-if="captcha_id">
                                            <div v-if="captcha_img" class="mb-2 d-flex justify-content-center align-items-center">
                                                <div  style="border:1px solid white;border-radius:5px" class="inline-flex bg-white">
                                                    <img v-if="captcha_img" :src="captcha_img" id="captchaImg" />
                                                </div>
                                                <div class="ms-2" style="width:20px; cursor:pointer" @click="setNewCaptcha()">
                                                    <img src="@/assets/imgs/ic-arrows-rotate-solid.svg" title="Újat kérek" class="img-fluid" />
                                                </div>
                                            </div>
                                            <CustomInput
                                                label="Captcha"
                                                name="captcha"
                                                v-model="form.captcha"
                                                rules="required"
                                            />                                            
                                            <div v-if="captcha_error">
                                                <span class="input-error">Hibás captcha</span>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 d-flex justify-content-center mt-2 mb-0 my-sm-4">
                                                    <input 
                                                        class="btn btn-secondary" 
                                                        type="submit" 
                                                        value="Belépés"
                                                    >
                                                </div>
                                            </div>     
                                        </div>                                                                
                                        <div v-if="loginError" class="input-error mx-auto" style="width:fit-content;">{{loginError}}</div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 d-flex justify-content-center mt-1">                                       
                                                    <router-link :to="{name:'ForgottenPsw'}"                                                    
                                                        class="text-secondary fw-700 text-decoration-underline btn-link text-center mt-0 fs-14"
                                                    >
                                                        Elfelejtett jelszó?
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ValidationObserver>                     
                  </div>      
            </div>                        
    </div>    
</template>

<script>
import CustomInput from '@/components/base/CustomInput.vue'
import { ValidationObserver } from 'vee-validate'
import axios from 'axios'
// import errorMessages from "@/setup/errorMsgs.json"

export default {
    components: {
        ValidationObserver,
        CustomInput        
    },
    data () {
        return {
            captcha_img: null,
            captcha_id: null,
            isConditionAccepted: false,
            captcha_error: false,
           form: {
                captcha: null,
                email: '',
                password: ''
            }, 
            loginError: ""
        }
    },
    watch: {
        form:{
            handler: function(){
                this.isConditionAccepted = true;
            },
            deep: true
        },
        isConditionAccepted: function() {
                this.captcha_error = false;
                //get the capcha id
                axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_new_id').then(response => {
                    this.captcha_id = response.data.id;
                
                    //get the captcha img
                    axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_image?id='+this.captcha_id, {
                        responseType: 'blob',
                    }).then(response => {                          
                        this.showCapchaImg(response.data)
                        this.captcha_error = false;

                    }).catch(function (err) {
                        //NOT SUCCESS msg send
                        console.log(err);      
                        this.captcha_error = true;
                        
                    });
                })
            }            
        
    },
    methods: {
        setNewCaptcha(){
            this.captcha_error = false;
            this.form.captcha = "";
            axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_new_id').then(response => {
                this.captcha_id = response.data.id;
            
                //get the captcha img
                axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_image?id='+this.captcha_id, {
                    responseType: 'blob',
                }).then(response => {                           
                    this.showCapchaImg(response.data) 
                    this.captcha_error = false;
                    
                }).catch(function (err) {
                    //NOT SUCCESS msg send
                    console.log(err);      
                    this.captcha_error = true;
                    
                });
            })
        },
        showCapchaImg(imgSource){                    
            const myImgUrl = URL.createObjectURL( imgSource );            
            this.captcha_img = myImgUrl     
        },
        onSubmit(){        
            let _this = this       

            this.login(this.form.email, this.form.password, this.form.captcha, this.captcha_id).then((res)=>{
                if (res.data.status == true){
                    //reset form
                    window.dataLayer.push({'event': 'form-submit', 'form-name': 'login', 'form-submit': true, 'form-error':''})
                    if (this.$route.name == 'EmailVerification'){
                        this.$router.push({name:'Home', params:{ scrollTo: 'palyazat' }})
                    } else {
                        this.setFormDefaultState()    
                    }
                    
                }
                                
           }).catch((err)=>{  
                this.setNewCaptcha();          
                //this.setErrorMsgs(err)
                _this.setErrorMsgs(err, 'observerLogin', 'login').catch(()=>{
                    this.loginError = "Sikertelen bejelentkezés"
                    this.setNewCaptcha();

                   window.dataLayer.push({'event': 'form-submit', 'form-name': 'login', 'form-submit': false, 'form-error':'Error to login'})

                    setTimeout(() => {
                        this.loginError = ""
                    }, 5000);
                })
           })
        },
        setFormDefaultState(){
            this.form = {
                email: '',
                password: '',
                captcha: ''
            }
            this.captcha_img = null,
            this.captcha_id = null,
            this.isConditionAccepted = false,
            this.captcha_error = false
        },
        goToError(err){            
            
            this.setErrorMsgs(err, 'observerLogin', 'login').then(()=>{
                let firstError = document.querySelector('#loginModal .input-error')
                if (firstError)
                    firstError.scrollIntoView()                             
            })                                       
        },          
    }
}
</script>