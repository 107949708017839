<template>
    <main id="emailVerificationBlock">
        <Header />
        <div class="hero-modal-page">           
            <div class="hero-closed-container container-xl">
                <!-- TITLE -->
                <div class="row">                    
                    <div class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                          <div class="card bg-white form-inner-container form-inner-container-shadow">
                                <div class="card-dialog">
                                    <div class="card-content">                
                                        <div class="text-center">   
                                            <img 
                                            v-if="validated == 'success'"
                                            src="@/assets/imgs/ic-mail.svg" 
                                            class="img-fluid mb-4"
                                        >                                           
                                            <h5 class="text-center w-100 text-primary fw-700 mb-5">E-mail cím megerősítése</h5>
                                        </div>
                                        <div class="card-body text-center text-secondary pt-0">
                                            <div class="">{{statusMsg[validated]}}</div>
                                            <!-- login form if succes -->                                            
                                            <div v-if="validated == 'success'">
                                                 <LogIn  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                    </div>
                </div>
            </div>
        </div>          
        <Footer />
    </main>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import LogIn from "@/components/LogIn.vue"

export default {
    components:{
        Header,
        Footer,
        LogIn
    },
    data(){
        return{
            validated: 'dontknow',
            statusMsg:{
                'success': "A felhasználói fiók aktiválva.",
                'faild': 'Nem sikerült a felhasználói fiókot megerősíteni',
                "missingtoken": 'Hiba'
            }
        }
    },
    methods:{
        closeModal(){

        },    
        goToForm(){
           
        }
    },
    created(){
            

          if ( this.$route.name == 'EmailVerification' &&  this.$route.params.secret){
            
                var _this = this;
                this.post('emailverification',{email_token: this.$route.params.secret}).then((resp)=>{
                    if (resp.data.status == true)
                    {
                        this.validated = 'success';
                        _this.$store.state.token = resp.data.token;
                        sessionStorage.setItem("token", resp.data.token);                        
                        _this.$store.state.user = resp.data.user;                        
                    
                    }
                }).catch(()=>{
                     this.validated = 'faild'                                        
                })
            }
            else
            {
                this.validated = 'missingtoken'
            }

            if (document.querySelectorAll('meta[content="noindex, nofollow"]').length === 0) {
                var m = document.createElement('meta'); 
                m.name = 'robots'; 
                m.content = 'noindex, nofollow'; 
                document.head.appendChild(m);
            }

    }
}
</script>