<template>
    <span class=" input-error"  v-if=" error" :class="{'active-error': error}" :id="id">{{ error}}</span>
</template>

<script>

export default {
    props: {
        error: {
            required: false,
            type: String            
        },
        id:{
            required:true,
            type: String
        }
    },
    watch:{
        error: function(newVal) {            
            if (newVal){
                let parentForm = document.getElementById(this.id).closest('[data-formname]')
                let parentFormName = parentForm.getAttribute('data-formname')
                //console.log('új error', parentFormName,  this.error + ' - ' + this.id )
                window.dataLayer.push(
                    {
                        'event': 'form-submit', 
                        'form-name': parentFormName, 
                        'form-submit': false, 
                        'form-error': this.error + ' - ' + this.id
                    }
                );
            }
            
        }
    }
}
</script>